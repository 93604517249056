<template>
  <FarmsBrowse></FarmsBrowse>
</template>

<script>
import FarmsBrowse from "@/components/newFarms/FarmsBrowse";
export default {
  name: "Browse",
  components: { FarmsBrowse }
};
</script>

<style scoped></style>
